.cardWrapper {
  border: 1px solid #EDEBE9;
  padding: 10px;
}
.cardTitle {
  font-weight: 600;
  margin-bottom: 20px;
}

.cardDataName {
  font-weight: 600;
  margin-right: 10px;
}
