@import '../variables';

.pageTitle {
  font-size: 20px;
}

.fieldLabel {
  color: $titleColor;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.errorMessage {
  color: $errorMessageColor;
  font-style: italic;
  font-size: 12px;
  font-weight: 200;
}

.filterLabel {
  color: $secondColorText;
  font-weight: 400;
  font-size: 14px;
}

.optionalText {
  color: $secondColorText;
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
}

.infoCardsGroup {
  gap: 10px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
}
