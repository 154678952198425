@import '../../../styles/variables';
@import '../../../styles/components';
@import '../../../styles/layout';
@import '../../../styles/flex';

.infoPageContent {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.infoPageMenuContent {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.pageWrapperIntercom {
  overflow-y: auto;
  padding-top: 0;
}
.cardWrapper {
  border: 1px solid #EDEBE9;
  padding: 10px;
  width: 450px;
  height: 110px;
  display: flex;
  flex-flow: column;
}
.cardTitle {
  font-weight: 600;
  margin-bottom: 10px;
}

.cardDataGroup {
  gap: 10px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.buttonIconPositionBig {
  @extend .buttonIconPosition;
  height: 14px;
}
.statusIndicator {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.infoPageContentWhileNotReady {
  @extend .infoPageContent;
  justify-content: center;
  margin-top: 5%;
}
