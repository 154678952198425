@import '../../../styles/variables';
@import '../../../styles/flex';
@import '../../../styles/layout/index';
@import '../../../styles/components';

.saveBtn {
  background-color: $primaryTextColor;
  color: white;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 21px;
  gap: 8px;

  width: 72px;
  height: 34px;

  border-radius: 2px;
  cursor: pointer;
  &:disabled {
    background-color: $disabledBtnColor;
    cursor: not-allowed;
  }
}
