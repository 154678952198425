@import '../../../../styles/variables';
@import '../../../../styles/components';
@import '../../../../styles/layout';
@import '../../../../styles/flex';


.pageWrapperIntercomConfigContent {
  width: 100%;
  padding: 0 0 20px 20px;
}
.pageWrapperIntercomConfigContentManagement {
  width: 100%;
}
.panelRowSpaceBetweenCustom {
  @extend .panelRow;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: start;
  gap: 20px;
}

.panelRowCenterCustom {
  @extend .panelRow;
  justify-content: center;
  margin-bottom: 15px;
  gap: 20px;
}

.codecSelectionBox {
  display: flex;
  flex-flow: column;
  border: 1px solid #EDEBE9;
  height: 400px;
  padding: 5px;
  .codecRow {
    color: #605E5C;
    padding: 3px;
    cursor: pointer;
  }
}
