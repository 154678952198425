.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    display: flex;
    flex: 1;
    overflow: hidden;
}
