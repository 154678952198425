@import '../../../styles/variables';
@import '../../../styles/components';
@import '../../../styles/flex';
.dragDropZone {
  background-color: $dropZoneBackgroundColor;
  border: 1px solid $secondOptionColor;
  padding: 20px;
}
.dragDropZoneDisabled {
  @extend .dragDropZone;
  opacity: 0.3;
}

.browseBtnWrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uploadInfo {
  text-align: center;
  color: $secondColorText;
}
.fileNameBox {
  color: $secondColorText;
  display: flex;
  align-items: center;
  justify-content: center;
  size: 12px;
  width: 100%;
  img {
    margin-left: 5px;
    cursor: pointer;
  }
}
.actionBrowseBtn {
  @extend .actionButtonGray;
  height: auto;
  line-height: normal;
}
