@import '../../styles/variables';
@import '../../styles/components';
@import '../../styles/layout';
@import '../../styles/flex';

.mainSearch {
  width: 33%;
  margin: 20px 0;
}

.gridIntercom {
  min-height: 80%;
  width: 100%;
}

.modalActionBar {
  @extend .panelRowJustifyContentEnd;
  margin-top: 20px;
  width: 100%;
  button {
    margin-left: 20px;
  }
}

.selectedStateBox {
  color: $titleColor;
  margin-right: 10px;
  white-space: nowrap;
}
