.k-expander {
  width: -webkit-fill-available;

  .k-expander-title {
    color: black;
    font-weight: 600;
  }

  .k-expander-content {
    padding: 0;
  }

  .k-expander-indicator {
    order: -1;
    margin: 0 10px 0 0;
  }

  .k-expander-header {
    background: #F3F3F3;
  }
}

.k-expander.k-focus::after {
  outline: 2px solid #EDEBE9 !important;
  border: none !important;
  inset: 0 !important;
}

.k-expander.k-expanded {
  background-color: #DEDEDE;
}

#IntercomConfigMenu {
  width: 170px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#IntercomConfigMenuParent {
  background: #FAF9F8;
  display: flex;
  cursor: pointer;
  font-weight: 600;
}

#IntercomConfigMenuItem {
  border: 1px solid #EDEBE9;
  padding: 10px 10px 10px 20px;
  background: #FAF9F8;
  display: flex;
  height: 40px;
  max-height: 40px;
  cursor: pointer;
  font-weight: 600;
}
#IntercomConfigMenuItem:hover {
  background-color: #DEDEDE !important;
}

#SelectedMenuItem {
  font-weight: 800;
  background-color: #DEDEDE !important;
}

#IntercomConfigContentExpander {
  .k-expander {
    border: none;
  }
  .k-expander.k-focus {
    border: none;
  }
  .k-expander-content {
    padding: 20px 0 20px 20px;
  }
}
