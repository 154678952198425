@import '../variables';

.k-button-link {
  &:hover,
  &:focus {
    text-decoration: none;

    &:after {
      outline: none;
    }
  }
}
