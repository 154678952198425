@import '../../../styles/variables';
@import '../../../styles/components';
@import '../../../styles/layout';
@import '../../../styles/flex';

.modalActionBar {
  @extend .panelRowJustifyContentEnd;
  margin-top: 20px;
  width: 100%;
  button {
    margin-left: 20px;
  }
}

.modalBodyBorder {
  border: 1px solid $borderColor;
  height: 85%;
  overflow-y: auto;
  white-space: pre;
}
