.panelRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}

.panelRowSpaceBetween {
  @extend .panelRow;
  justify-content: space-between;
}

.panelRowJustifyContentEnd {
  @extend .panelRow;
  justify-content: end;
}
