@import '../variables';

.requiredStar {
  margin-left: 3px;
  &:after {
    content: '*';
    color: red;
  }
}

.viewWrapper {
  display: flex;
  flex-flow: column;
  width: 50%;
}
.labelView {
  font-weight: 600;
  display: flex;
  margin-bottom: 10px;
  cursor: default;
}

.valueView {
  font-weight: 400;
  display: flex;
}

.labelRadio {
  cursor: pointer;
}
