html,
body {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%;
}

body {
    padding-right: 0 !important;
    font-family: "Segoe UI", Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
