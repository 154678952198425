.viewWrapper {
  display: flex;
  flex-flow: column;
}
.labelView {
  font-weight: 600;
  display: flex;
  margin-bottom: 10px;
  cursor: default;
}

.valueView {
  font-weight: 400;
  display: flex;
}
