@import '../variables';

.k-grid-table-wrap {
  .k-grid-norecords {
    height: 45px;
  }
}
.k-grid-content {
  overflow-y: auto !important;
}
.k-grid .k-table-th:focus, .k-grid .k-table-td:focus {
  box-shadow: none !important;
}
.k-grid-header {
  padding: 0 !important;

}
thead.k-table-thead {
  background-color: $gridHeaderColor !important;
}
.k-table-td {
  border-bottom: 1px solid $gridHeaderColor !important;
}
.k-grid-content .k-table-row:last-child > td, .k-grid-content .k-table-row:last-child > .k-table-td, .k-grid-content-locked .k-table-row:last-child > td, .k-grid-content-locked .k-table-row:last-child > .k-table-td {
  border-bottom: 1px solid $gridHeaderColor !important;
}
.k-table-th.k-sorted {
  background-color: inherit !important;
}

#intercomGrid {
  .k-table-row {
    cursor: pointer !important;
  }
}
